<template>
  <div class="req-temp-container">
    <vs-card class="card">
      <div slot="header">
        <h3 class="font-normal mb-0 text-base sm:text-lg md:text-xl lg:text-2xl">Pay Now payment methods</h3>
      </div>

      <p class="block mb-8">
        Configure the Pay Now payment method fees for this product.
      </p>

      <div class="cards-grid">
        <debit-card-component
          v-for="(card, index) in cards"
          :card="card"
          :key="index"
          @edit="handleEditPopup(card)"
        ></debit-card-component>
      </div>

      <div></div>
    </vs-card>
    <vs-card class="card mt-5 legals">
      <div slot="header">
        <h3 class="font-normal mb-0 text-base sm:text-lg md:text-xl lg:text-2xl">Pay Now terms & conditions - customers</h3>
      </div>
      <div>
        <p class="mb-10">
          Create the T&Cs customers must accept on the payment page when making
          payments for this product.
        </p>

        <div class="legals__item">
          <div
            class="mb-6 flex flex-row items-center"
            :class="{
              'justify-end': customerTermsAndConditions.length > 0,
            }"
          >
            <vs-button
              v-round
              class="w-2/12"
              v-if="customerTermsAndConditions.length === 0"
              color="primary"
              icon="add"
              @click="createLegal('CUSTOMER')"
              >New Document</vs-button
            >

            <vs-button
              v-else
              class="only-border-btn"
              v-round
              size="medium"
              @click="createLegal('CUSTOMER', true)"
              ><plus-icon size="1.5x" class="button-icon"></plus-icon>New
              Version</vs-button
            >
          </div>

          <vs-table
            :data="customerTermsAndConditions"
            v-if="customerTermsAndConditions.length > 0"
          >
            <template slot="thead">
              <vs-th> Document Name </vs-th>
              <vs-th> Date Created </vs-th>
              <vs-th> Version </vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="capitalize" :data="data[indextr].header">
                  {{ data[indextr].documentName }}
                </vs-td>

                <vs-td :data="data[indextr].createdAt">
                  {{ formatScheduleDate(data[indextr].createdAt) }}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  {{ data[indextr].version }}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  <span class="active-badge" v-if="indextr === 0">Active</span>
                </vs-td>
                <vs-td :data="data[indextr].id" class="view-btn">
                  <a class="underline" @click="viewLegal(tr._id)">view</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-card>

    <vs-card class="card mt-5 legals">
      <div slot="header">
        <h3 class="font-normal mb-0 text-base sm:text-lg md:text-xl lg:text-2xl">Pay Now terms & conditions - merchants</h3>
      </div>
      <div>
        <p class="mb-6">
          Create the T&Cs merchants must accept when enabling this product on
          their account.
        </p>

        <div class="legals__item">
          <div
            class="mb-6 flex flex-row items-center"
            :class="{
              'justify-end mb-6': merchantTermsAndConditions.length > 0,
            }"
          >
            <vs-button
              v-round
              class="w-2/12"
              v-if="merchantTermsAndConditions.length === 0"
              color="primary"
              icon="add"
              @click="createLegal('MERCHANT')"
              >New Document</vs-button
            >

            <vs-button
              v-else
              class="only-border-btn"
              v-round
              size="medium"
              @click="createLegal('MERCHANT', true)"
              ><plus-icon size="1.5x" class="button-icon"></plus-icon>New
              Version</vs-button
            >
          </div>

          <vs-table
            :data="merchantTermsAndConditions"
            v-if="merchantTermsAndConditions.length > 0"
          >
            <template slot="thead">
              <vs-th class="legals-th"> Document Name </vs-th>
              <vs-th class="legals-th"> Date Created </vs-th>
              <vs-th class="legals-th"> Version</vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="capitalize" :data="data[indextr].header">
                  {{ data[indextr].documentName }}
                </vs-td>

                <vs-td :data="data[indextr].createdAt">
                  {{ formatScheduleDate(data[indextr].createdAt) }}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  {{ data[indextr].version }}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  <span class="active-badge" v-if="indextr === 0">Active</span>
                </vs-td>
                <vs-td :data="data[indextr].id" class="view-btn">
                  <a class="underline" @click="viewLegal(tr._id)">view</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-card>

    <vs-popup class="add-card-popup" :active.sync="addCardPopupActive" @close="closeModal" :title="'Edit Card'">
      <vs-row>
        <vs-col vs-w="12" class="mb-4">
          <label>Name</label>
          <vs-input class="w-full" v-validate="'required'" data-vv-validate-on="blur" v-model="title" placeholder="Title" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
        </vs-col>

        <div class="grid grid-cols-2 gap-x-10 mb-6">
          <div>
            <label>MSF <span class="required-text">*</span></label>
            <vs-select placeholder="Select MSF rate" class="selectExample" v-model="msf" @change="checkMsf">
              <vs-select-item :key="index" :value="msfRate.msf" :text="msfRate.displayMsf" v-for="(msfRate, index) in msfRates" />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('msf')">{{ errors.first("msf") }}</span>
          </div>
          <div>
            <label>Surcharge to customer <span class="required-text">*</span></label>
            <vs-input class="w-8/12" v-validate="`required|min_value:1|max_value:${msf}`" v-model="surcharge" data-vv-validate-on="blur" name="surcharge" />
            <span class="text-danger text-sm" v-show="errors.has('surcharge')">{{ errors.first("surcharge") }}</span>
          </div>
        </div>

        <vs-col vs-w="12">
          <VueFileAgent
            class="no-animation"
            ref="vueFileAgent"
            :multiple="false"
            :meta="true"
            :deletable="true"
            :accept="'image/*'"
            :maxSize="`${maxFileSize}MB`"
            :helpText="'Choose icon file'"
            :errorText="{
              type: 'Invalid file type. Only images are allowed',
              size: `Files should not exceed ${maxFileSize}MB in size`,
            }"
            @beforedelete="onBeforeDelete($event)"
            @delete="fileDeleted($event)"
            v-model="icon"
          />
        </vs-col>
        <vs-col class="flex justify-end lg:mt-5 sm:mt-4">
          <vs-button
            class="text-xs mx-4"
            v-round
            :disabled="!icon || !title || !slug"
            color="primary"
            @click="updateCard"
            v-if="this.isUpdate"
            >Save</vs-button
          >
          <a
            class="text-xs flex items-center underline mid-blue"
            @click="closeModal"
            >Cancel</a
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import DebitCardComponent from "@/views/components/DebitCardComponent";
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import _ from "lodash";
import moment from "moment";
import { PlusIcon } from "vue-feather-icons";

Vue.use(VueFileAgent);

export default {
  components: {
    DebitCardComponent,
    PlusIcon,
  },
  data() {
    return {
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      cards: [],
      msf: "",
      surcharge: "",
      addCardPopupActive: false,
      title: "",
      slug: "",
      icon: "",
      id: "",
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
      isUpdate: false,
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
      msfRates: [],
    };
  },
  methods: {
    ...mapActions("card", ["fetchCardList", "createCardDetails", "deleteCard", "updateCardDetailsById"]),
    ...mapActions("s3ServiceModule", ["fetchFile"]),
    ...mapActions("setting", ["fetchSetting"]),
    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    createLegal(user, newVersion = false) {
      this.$router.push({
        name: "cards-legals-editor",
        query: { user, newVersion },
      });
    },
    async getCards(page) {
      const params = `?page=${page}&limit=10&sort=desc`;
      await this.fetchCardList(params)
        .then((res) => {
          this.cards = res.data.data.docs;
          this.totalData = res.data.data.pagination.total
            ? res.data.data.pagination.total
            : 0;
        })
        .catch((err) => {

        });
    },

    async getMsfSetting() {
      await this.fetchSetting('surcharge')
      .then((res) => {
        this.msfRates = res.data.data.map((element) => {
          return {
            ...element,
            displayMsf: element.msf + " %"
          };
        });
      }).catch((ex) => {
        this.$vs.notify({
          title: "Error",
          text: "Error fetching setting",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },

    async handleEditPopup(card) {
      this.title = card.title;
      this.slug = card.slug;
      this.id = card._id;
      this.msf = card.msf ? card.msf : "1.50";
      this.surcharge = card.surcharge ? card.surcharge : "1.50";

      if (this.errors.has(`msf`)) {
        this.errors.remove(`msf`);
      }

      await this.fetchFile(card.icon)
        .then(result => {
          this.icon = {
            name: card.icon,
            type: 'image/svg+xml',//blob.type,
            size: '200',//blob.size,
            url: result.data.data.url,
            iconName: card.icon,
          };

          this.isUpdate = true;
          this.addCardPopupActive = true;
        })

    },
    getLegals() {
      this.$vs.loading();
      this.$store
        .dispatch("legals/fetchAllLegals", "CARD")
        .then((result) => {
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: "Error",
            text: "Error fetching legals",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },
    viewLegal(id) {
     const routeData =  this.$router.resolve({
        name: "terms-view",
        params: { id },
        query: { type: "CARD" },
      });
      window.open(routeData.href, '_blank');
    },
    handleEdit(card) {
      this.isUpdate = true;
      this.addCardPopupActive = true;
    },
    createCardPopup() {
      this.title = "";
      this.slug = "";
      this.icon = "";
      this.id = "";
      this.isUpdate = false;
      this.addCardPopupActive = true;
    },
    async createCard() {
      let uploadedFile = this.icon;
      const formdata = new FormData();
      formdata.append("title", this.title);
      formdata.append("slug", this.slug);
      formdata.append("icon", uploadedFile.file);

      let obj = {
        data: formdata,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.$vs.loading();
      await this.createCardDetails(obj)
        .then((res) => {
          this.$vs.notify({
            title: "Cards",
            text: "Card has been created successfully.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success"
            ,
          });
          this.$vs.loading.close();
          this.addCardPopupActive = false;
          this.getCards(this.pageNumber);
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          this.$vs.loading.close();
        });
    },
    async updateCard() {
      if (this.surcharge > this.msf) {
        this.errors.add({
          "field": 'surcharge',
          "msg": `The surcharge field must be ${this.msf} or less`
        });
        return;
      }
      let uploadedFile = this.icon;

      const isValidMsf = this.checkMsf();

      if (!isValidMsf) {
        return;
      }

      const formdata = new FormData();
      formdata.append("title", this.title);
      formdata.append("surcharge", this.addZeroes(this.surcharge));
      formdata.append("sf", this.addZeroes(this.surcharge));
      formdata.append("msf", this.addZeroes(this.msf));
      formdata.append("slug", this.slug);
      formdata.append( "icon", uploadedFile.iconName ? uploadedFile.iconName : uploadedFile.file);

      let obj = {
        id: this.id,
        data: formdata,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.$vs.loading();
      await this.updateCardDetailsById(obj)
        .then((res) => {
          this.$vs.notify({
            title: "Cards",
            text: "Card has been updated successfully.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close();
          this.addCardPopupActive = false;
          this.getCards(this.pageNumber);
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    closeModal() {
      this.addCardPopupActive = false;
    },
    onBeforeDelete: function (fileRecord) {
      if (confirm("Are you sure you want to delete?")) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        this.icon = "";
      }
    },
    fileDeleted: function (fileRecord) {
      this.icon = "";
    },
    removeCardPopup(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure you want to delete this card?",
        accept: this.removeCard,
        parameters: id,
      });
    },
    async removeCard(id) {
      this.$vs.loading();
      await this.deleteCard(id)
        .then((res) => {
          this.$vs.notify({
            title: "Cards",
            text: "Card has been deleted successfully.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });

          this.$vs.loading.close();
          this.getCards(this.pageNumber);
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    checkMsf() {
      const objIndex = this.msfRates.findIndex((obj) => obj.msf == this.msf);
      if (objIndex < 0) {
        this.errors.add({
          "field" : "msf",
          "msg": "Please select valid msf from the list"
        });
        return false;
      } else {
        if (this.errors.has(`msf`)) {
          this.errors.remove(`msf`);
        }
      }
      return true;
    }
  },
  watch: {
    pageNumber(val) {
      this.getCards(val);
    },
  },
  created() {
    this.getMsfSetting();
    this.getCards(this.pageNumber);
    this.getLegals();
  },
  computed: {
    ...mapState("legals", ["legals"]),
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },

    merchantTermsAndConditions() {
      const terms = this.legals.filter((legal) => legal.user === "MERCHANT");
      return _.sortBy(terms, ["createdAt"]).reverse();
    },

    customerTermsAndConditions() {
      const terms = this.legals.filter((legal) => legal.user === "CUSTOMER");
      return _.sortBy(terms, ["createdAt"]).reverse();
    },
  },
};
</script>
