<template>
  <div class="debit-card flex flex-col">
    <!-- {{card}} -->
    <div class="card__header flex justify-between card-header">
      <div class="flex w-10/12 items-center">
        <S3ImageGenerator :is-base64="false" :custom-class="'card-image'" :document="card.icon" :key="card.icon"/>
        <h4 class="m-0 card__title ml-4">{{ card.title }}</h4>
      </div>
      <edit-2-icon class="edit-card w-5" @click="handleEdit"></edit-2-icon>
    </div>
    <div class="card__body">
      <div class="card__content">
        <span class="flex mb-1">MSF </span>
        <span>{{ addZeroes(this.card.msf) }} %</span>
      </div>
      <div class="card__content">
        <span class="mb-1">Surcharge to customer </span>
        <span> {{ addZeroes(this.card.surcharge) }} %</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
import S3ImageGenerator from "./S3ImageGenerator";
export default {
  name: "DebitCardComponent",
  components: {
    Edit2Icon,
    S3ImageGenerator
  },
  data() {
    return {
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
    };
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleEdit() {
      this.$emit("edit");
    },
    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>